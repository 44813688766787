//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SolutionsTab1 from '@/components/v3-components/solutionsTab1.vue'
import SolutionsTab2 from '@/components/v3-components/solutionsTab2.vue'
import SolutionsTab3 from '@/components/v3-components/solutionsTab3.vue'
import SolutionsTab4 from '@/components/v3-components/solutionsTab4.vue'
import SolutionsTab5 from '@/components/v3-components/solutionsTab5.vue'

export default {
  components: {
    SolutionsTab1,
    SolutionsTab2,
    SolutionsTab3
  },
  data () {
    return {
      activeTab: 1,
      solutionTabs: [SolutionsTab1, SolutionsTab2, SolutionsTab3, SolutionsTab4, SolutionsTab5]
    }
  },
  methods: {
    changeComponent ($event, item) {
      $event.preventDefault()
      if (item === this.activeTab) {
        return
      }
      this.activeTab = item
    }
  }
}
